<template>
  <v-dialog v-model="dialog" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" class="blue-font"><slot></slot></span>
    </template>

    <v-card v-if="!getuserLocale">

      <v-card-title class="text-h5 grey lighten-2 justify-center">
        Политика конфиденциальности редакция от 27/01/2022
      </v-card-title>

      <v-card-text>

        <vue-editor class="d-none"></vue-editor>

        <div class="ql-editor container">
          <p>&nbsp;</p>
          <p class="ql-align-justify">
            <span style="color: rgb(0, 0, 0)">Веб-сайт </span
            ><a
            href="http://www.platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="color: rgb(0, 0, 255)"
          >www.platforma.life</a
          ><span style="color: rgb(0, 0, 0)">
              (далее - Веб-сайт) уделяет большое внимание защите персональных
              данных своих пользователей. Пользователи Веб-сайта могут
              просматривать страницы сайтов без знания о себе никаких
              персональных данных. Однако для предоставления услуг,
              представленных в нашем Веб-сайте, нам нужны ваши контактные данные
              для того, чтобы мы могли связаться с вами и качественно
              предоставить вам выбранные услуги. Ваши личные данные мы не
              передаем третьим лицам и защищаем их конфиденциальность.</span
          >
          </p>
          <p class="ql-align-justify"><br /></p>
          <p class="ql-align-justify">
            <span style="color: rgb(0, 0, 0)"
            >Эта политика регулируется Законом Украины "О Защите персональных
              данных" от 01.06.2010 № 2297-VI</span
            >
          </p>
          <p class="ql-align-justify">&nbsp;</p>
          <p class="ql-align-center">
            <strong style="color: rgb(0, 0, 0)">ТЕРМИНОЛОГИЯ</strong>
          </p>
          <p class="ql-align-justify">
            <span style="color: rgb(0, 0, 0)"
            >Политике конфиденциальности упоминаются следующие термины:</span
            >
          </p>
          <p class="ql-align-justify"><br /></p>
          <p class="ql-align-justify">
            <strong style="color: rgb(20, 21, 23)">«Персональные данные»</strong
            ><span style="color: rgb(20, 21, 23)">
              — любая информация, относящаяся к прямо или косвенно определенному
              или определяемому физическому лицу (субъекту персональных
              данных).</span
          >
          </p>
          <p class="ql-align-justify">
            <strong style="color: rgb(0, 0, 0)"
            >«Обработка персональных данных» - </strong
            ><span style="color: rgb(0, 0, 0)"
          >любое действие (операция) или совокупность действий (операций),
              совершаемых с использованием средств автоматизации или без
              использования таких средств с персональными данными, включая сбор,
              запись, систематизацию, накопление, хранение, уточнение
              (обновления, изменение), извлечение, использование, передачу
              (распространение, предоставление, доступ), обезличивание,
              блокирование, удаление, уничтожение персональных данных.</span
          >
          </p>
          <p class="ql-align-justify">
            <strong style="color: rgb(0, 0, 0)"
            >«Конфиденциальность персональных данных» </strong
            ><span style="color: rgb(0, 0, 0)"
          >- обязательное для соблюдения Администратором или другим
              получившим доступ к персональным данным лицом требование не
              допускать их распространения без согласия субъекта персональных
              данных или наличия другого законного основания.</span
          >
          </p>
          <p class="ql-align-justify">
            <strong style="color: rgb(0, 0, 0)">«Пользователь» </strong
            ><span style="color: rgb(0, 0, 0)"
          >– лицо, имеющее доступ к Сайту, с помощью сети Интернет и
              использующее Веб-сайт.</span
          >
          </p>
          <p class="ql-align-justify">
            <strong style="color: rgb(0, 0, 0)">«Cookies» </strong
            ><span style="color: rgb(0, 0, 0)"
          >– небольшой фрагмент данных, отправленный веб-сервером и
              хранящийся на компьютере пользователя, который веб-клиент или
              веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при
              попытке открыть страницу соответствующего сайта.</span
          >
          </p>
          <p class="ql-align-justify">
            <strong style="color: rgb(0, 0, 0)">«IP-адрес» </strong
            ><span style="color: rgb(0, 0, 0)"
          >– уникальный сетевой адрес узла в компьютерной сети, построенной
              по протоколу IP.</span
          >
          </p>
          <p class="ql-align-justify">&nbsp;</p>
          <p class="ql-align-justify">&nbsp;</p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">1.</strong>
            <strong style="background-color: transparent"
            >ОБЩИЕ ПРОЛОЖЕНИЯ</strong
            >
          </p>
          <p><br /></p>
          <p>
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >1.1 </span
            >
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Данная политика конфиденциальности устанавливает порядок
              получения, хранения, обработки, использования и раскрытия
              персональных данных пользователей. Персональные пользовательские
              данные мы
            </span>
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >получаем от пользователей при оформлении заказа услуг через
              Веб-сайт. Обязательно для предоставления услуги информация
              обозначена специальным образом. Другая информация
            </span>
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(19, 18, 18)">
            </span>
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >предоставляет пользователю по его собственному усмотрению.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >1.2 </span
            >
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Конфиденциальность персональных данных – обязательное условие для
              соблюдения Веб-сайтом доступа к персональным данным пользователей,
              с требованием не допускать их распространение без согласия</span
            >
          </p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >пользователя или наличию иному законному основанию.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >1.3 </span
            >
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Использование пользователем Веб-сайта </span
            ><a
            href="http://www.platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="color: rgb(0, 0, 255)"
          >www.platforma.life</a
          ><span
            style="color: rgb(19, 18, 18); background-color: transparent"
          >
              означает согласие с политикой конфиденциальности и условиями
              обработки персональных данных.</span
          >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >1.4 </span
            >
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >В случае несогласия с условиями политики, пользователь должен
              прекратить использование Веб-сайта.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >1.5
            </span>
            <span style="background-color: transparent; color: rgb(19, 18, 18)">
              Эта политика применяется только для сайта&nbsp; </span
            ><a
            href="http://www.platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="color: rgb(0, 0, 255)"
          >www.platforma.life</a
          ><span style="color: rgb(19, 18, 18); background-color: transparent"
          >, но не контролирует и не несет ответственности за сайты третьих
              лиц.</span
          >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >1.6 </span
            >
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Администрация сайта не проверяет подлинность персональных данных,
              предоставляемых пользователем Веб-сайту.</span
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p class="ql-align-center">
            <strong>2.</strong>
            <strong>ПЕРСОНАЛЬНЫЕ ДАННЫЕ</strong>
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >2.1
            </span>
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Веб-сайт собирает следующие личные данные, а именно:</span
            >
          </p>
          <ul>
            <li class="ql-align-justify">
              <span style="background-color: transparent">имя фамилия;</span>
            </li>
            <li class="ql-align-justify">
              <span style="background-color: transparent"
              >контактный телефон;</span
              >
            </li>
            <li class="ql-align-justify">
              <span style="background-color: transparent"
              >электронной почты.</span
              >
            </li>
          </ul>
          <p class="ql-align-justify">2.2
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Также мы получаем от вас эту информацию по телефону.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >2.3 </span
            >
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Другая информация предоставляется пользователем по его
              усмотрению.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >2.4 </span
            >
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Веб-сайт </span
            ><a
            href="http://www.platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="color: rgb(0, 0, 255)"
          >www.platforma.life</a
          ><span
            style="color: rgb(19, 18, 18); background-color: transparent"
          >
              не собирает ip-адреса пользователей.</span
          >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">3.</strong>
            <strong style="background-color: transparent"
            >ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">3.1 <span style="background-color: transparent; color: rgb(19, 18, 18)">Целью
            обработки персональных данных является обеспечение реализации гражданско-правовых отношений,
            предоставление/получение и осуществление расчетов по приобретенным услугам.</span>
          </p>
          <p><br /></p>
          <p class="ql-align-justify">3.2 <span style="background-color: transparent; color: rgb(19, 18, 18)"
          >Веб-сайт собирает и хранит только личные данные, которые
              необходимы для предоставления услуг.</span
          >
          </p>
          <p><br /></p>
          <p>
            3.3
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Веб-сайт собирает данные для связи с пользователем, включая
              уведомления о предоставлении услуг, информирование пользователя о
              предоставлении услуг, а также для обработки запросов и заявок от
            </span>
          </p>
          <p>
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >пользователей.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            3.4
            <span style="background-color: transparent; color: rgb(19, 18, 18)"
            >Обработка персональных пользовательских данных осуществляется без
              ограничения срока, любым средством.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">4.</strong>
            <strong style="background-color: transparent"
            >УСЛОВИЯ ПРЕДОСТАВЛЕНИЯ ДОСТУПА К БАЗЫ ДАННЫХ</strong
            >
          </p>
          <p class="ql-align-justify"><br /></p>
          <p class="ql-align-justify">
            4.1
            <span style="background-color: transparent"
            >Мы не передаем персональные данные пользователей третьим лицам,
              кроме случаев, определенных законодательством Украины.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            4.2
            <span style="background-color: transparent"
            >Раскрытие персональных пользовательских данных осуществляется при
              заказе и получении пользователем услуг через Веб-сайт. В
              частности, пользователь соглашается с тем, что Веб-сайт имеет
              право
            </span>
          </p>
          <p class="ql-align-justify">
            <span style="background-color: transparent"
            >передавать личные данные исключительно с целью выполнения своих
              обязательств перед пользователями.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify">
            4.3
            <span style="background-color: transparent"
            >При потере или разглашении персональных данных веб-сайт
              информирует немедленно пользователя.</span
            >
          </p>
          <p class="ql-align-justify"><br /></p>
          <p class="ql-align-justify">
            4.4
            <span style="background-color: transparent"
            >Веб-сайт принимает необходимые организационные и технические меры
              для защиты персональной информации от неправомерного или
              случайного доступа, уничтожения, блокирования, копирования,
            </span>
          </p>
          <p class="ql-align-justify">
            <span style="background-color: transparent"
            >распространения, а также от других неправомерных действий третьих
              лиц.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">5.</strong>
            <strong style="background-color: transparent"
            >ИЗМЕНЕНИЕ ПЕРСОНАЛЬНЫХ ИНФОРМАЦИЙ ПОЛЬЗОВАТЕЛЕЙ</strong
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p class="ql-align-justify">
            5.1
            <span style="background-color: transparent"
            >Пользователь может в любой момент изменить (обновить, дополнить,
              удалить) предоставленную им персональную информацию или ее часть,
              отправив электронное письмо с соответствующим требованием по
            </span>
          </p>
          <p class="ql-align-justify">
            <span style="background-color: transparent">адресу </span
            ><a
            href="mailto:contact@platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="background-color: transparent; color: rgb(0, 0, 255)"
          >contact@platforma.life</a
          ><span style="background-color: transparent">.</span>
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent"
            >6. ИЗМЕНЕНИЕ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</strong
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p class="ql-align-justify">
            6.1
            <span style="background-color: transparent"
            >Мы имеем право изменить условия политики конфиденциальности. В
              таком случае мы заменим версию документа на странице "политика
              конфиденциальности". Пожалуйста, периодически пересматривайте
            </span>
          </p>
          <p class="ql-align-justify">
            <span style="background-color: transparent"
            >этот документ, чтобы быть проинформированным о том, как Веб-сайт
              защищает личные данные своих пользователей.</span
            >
          </p>
          <p class="ql-align-justify"><br /></p>
          <p class="ql-align-justify">
            6.2
            <span style="background-color: transparent"
            >В случае несогласия с любыми изменениями, Пользователь должен
              прекратить пользоваться сайтом, а также потребовать, чтобы
              Администрация сайта данных удалила его Персональные данные.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">7.</strong>
            <strong style="background-color: transparent"
            >ПОЛИТИКА ПО ФАЙЛАМ COOKIE</strong
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p>
            7.1
            <span style="background-color: transparent"
            >Сайт собирает информацию о посещении Вами наших веб-сайтов с
              целью получения статистики посещений и эффективности их
              использования, формировании персонального подхода и адаптации к
              интересам
            </span>
          </p>
          <p class="ql-align-justify">
            <span style="background-color: transparent"
            >каждого пользователя. Мы делаем это с помощью различных
              технологий, одна из которых – cookie-файлы. Это данные, которые
              веб-сайт может отправить вашему браузеру, и они будут сохранены на
              вашем
            </span>
          </p>
          <p class="ql-align-justify">
            <span style="background-color: transparent"
            >компьютере для дальнейшей идентификации его веб-сайтом.</span
            >
          </p>
          <p><br /></p>
          <p>
            7.2
            <span style="background-color: transparent"
            >Файлы Cookie используются для оценки эффективности веб-сайтов
              (например, для измерения количества посетителей и
              продолжительности их пребывания на сайте), выявления наиболее
              интересных и
            </span>
          </p>
          <p>
            <span style="background-color: transparent"
            >посещаемых тем и страниц, а также для обеспечения удобства
              навигации и пользования сайтом, без сбора какой-либо персональной.
              информации. Также cookie-файлы могут использоваться для
              формирования
            </span>
          </p>
          <p>
            <span style="background-color: transparent"
            >персонального подхода на основе уже имеющегося опыта
              взаимодействия пользователя с сайтом и его преимуществами. По
              прошествии времени эта информация позволяет улучшить опыт.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">8.</strong>
            <strong style="background-color: transparent"
            >ОБРАТНАЯ СВЯЗЬ.&nbsp;</strong
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p>
            8.1
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
            >Все предложения или вопросы по поводу этой политики следует
              уведомлять Веб-сайт по адресу </span
            ><a
            href="mailto:contact@platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="background-color: transparent; color: rgb(0, 0, 255)"
          >contact@platforma.life</a
          ><span style="background-color: transparent; color: rgb(0, 0, 0)"
          >.</span
          >
          </p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Принимаю </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="getuserLocale">
      <v-card-title class="text-h5 grey lighten-2 justify-center">
        Політика конфіденційності редакція від 27/01/2022
      </v-card-title>

      <v-card-text>

        <vue-editor class="d-none"></vue-editor>

        <div class="ql-editor container">
          <p><br /></p>
          <p class="ql-indent-1">
            <span style="color: rgb(0, 0, 0)">Веб-сайт </span
            ><a
            href="http://www.platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="color: rgb(0, 0, 255)"
          >www.platforma.life</a
          ><span style="color: rgb(0, 0, 0)">
              (надалі — Веб-сайт) приділяє велику увагу захисту персональних
              даних своїх користувачів. Користувачі Веб-сайту можуть переглядати
              сторінки сайтів без знання про себе ніяких персональних даних.
              Однак для надання послуг, представлених в нашому Веб-сайті, нам
              потрібні ваші контактні дані для того, щоб ми могли зв'язатися з
              вами, та якісно надати вам обрані послуги. Ваші особисті дані ми
              не передаємо третім особам і захищає їх конфіденційність.</span
          >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-indent-1">
            <span style="color: rgb(0, 0, 0)"
            >Ця політика регулюється Законом України "Про Захист персональних
              даних" від 01.06.2010 № 2297-VI</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="color: rgb(0, 0, 0)">ТЕРМІНОЛОГІЯ</strong>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent; color: rgb(0, 0, 0)"
            >В Політиці конфіденційності згадуються такі терміни:</span
            >
          </p>
          <p class="ql-align-justify ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent; color: rgb(0, 0, 0)"
            >«Персональні дані»</strong
            ><span style="background-color: transparent; color: rgb(0, 0, 0)">
              - будь-яка інформація, що відноситься до прямо або опосередковано
              визначеної або визначальної фізичної особи (суб'єкта персональних
              даних).</span
          >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent; color: rgb(0, 0, 0)"
            >«Обробка персональних даних»</strong
            ><span style="background-color: transparent; color: rgb(0, 0, 0)">
              - будь-яка дія (операція) або сукупність дій (операцій), що
              здійснюються з використанням засобів автоматизації або без
              використання таких засобів з персональними даними, включаючи збір,
              запис, систематизацію, накопичення, зберігання, уточнення
              (оновлення, зміна), витяг, використання, передачу (поширення,
              надання, доступ), знеособлення, блокування, видалення, знищення
              персональних даних.</span
          >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent; color: rgb(0, 0, 0)"
            >«Конфіденційність персональних даних»</strong
            ><span style="background-color: transparent; color: rgb(0, 0, 0)">
              - обов'язкова для дотримання Адміністратором або іншою особою, яка
              отримала доступ до персональних даних, вимога не допускати їх
              поширення без згоди суб'єкта персональних даних або наявності
              іншої законної підстави.</span
          >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent; color: rgb(0, 0, 0)"
            >«Користувач»</strong
            ><span style="background-color: transparent; color: rgb(0, 0, 0)">
              - особа, яка має доступ до Сайту, за допомогою мережі Інтернет і
              використовує Веб-сайт.</span
          >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent; color: rgb(0, 0, 0)"
            >«Cookies»</strong
            ><span style="background-color: transparent; color: rgb(0, 0, 0)">
              - невеликий фрагмент даних, відправлений веб-сервером і
              зберігається на комп'ютері користувача, який веб-клієнт або
              веб-браузер кожен раз пересилає веб-серверу в HTTP-запиті при
              спробі відкрити сторінку відповідного сайту.</span
          >
          </p>
          <p class="ql-align-justify ql-indent-1">
            <strong style="background-color: transparent; color: rgb(0, 0, 0)"
            >«IP-адреса»</strong
            ><span style="background-color: transparent; color: rgb(0, 0, 0)">
              - унікальна мережева адреса вузла в комп'ютерній мережі,
              побудованій за протоколом IP.</span
          >
          </p>
          <p><br /></p>
          <ol>
            <li class="ql-align-center">
              <strong style="background-color: transparent"
              >ЗАГАЛЬНІ ПОЛОЖЕННЯ</strong
              >
            </li>
          </ol>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            1.1
            <span style="background-color: transparent"
            >Дана політика конфіденційності встановлює порядок отримання,
              зберігання, обробки, використання і розкриття персональних даних
              користувачів. Персональні дані користувачів ми
            </span>
          </p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent"
            >отримуємо від користувачів при оформленні замовлення послуг через
              Веб-сайт. Обов'язково для надання послуги інформація позначені
              спеціальним чином. Інша інформація надається користувачеві на його
              власний розсуд.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            1.2
            <span style="background-color: transparent"
            >Конфіденційність персональних даних - обов'язкова умова для
              дотримання Веб-сайтом доступу до персональних даних користувачів,
              з вимогою не допускати їх поширення без згоди
            </span>
          </p>
          <p class="ql-align-justify ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            1.3
            <span style="background-color: transparent"
            >користувача або наявності іншої законної підстави.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            1.4
            <span style="background-color: transparent"
            >Використання користувачем Веб-сайту </span
            ><a
            href="http://www.platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="color: rgb(0, 0, 255)"
          >www.platforma.life</a
          ><span style="background-color: transparent">
              означає згоду з цією політикою конфіденційності та умовами обробки
              персональних даних.</span
          >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">1.5 </span>
            <span style="background-color: transparent"
            >У разі незгоди з умовами політики, користувач повинен припинити
              використання Веб-сайту.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">1.6 </span>
            <span style="background-color: transparent"
            >Ця політика застосовується тільки для Веб-сайту </span
            ><a
            href="http://www.platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="color: rgb(0, 0, 255)"
          >www.platforma.life</a
          ><span style="background-color: transparent"
          >, та не контролює і не несе відповідальність за сайти третіх
              осіб.</span
          >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">1.7 </span>
            <span style="background-color: transparent"
            >Адміністрація сайту не перевіряє достовірність персональних
              даних, що надаються користувачем Веб-сайту.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">2.</strong>
            <strong style="background-color: transparent"
            >ПЕРСОНАЛЬНІ ДАНІ</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">2.1 </span>
            <span style="background-color: transparent"
            >Веб-сайт збирає такі особисті дані, а саме:</span
            >
          </p>
          <ul>
            <li class="ql-align-justify ql-indent-1">
              <span style="background-color: transparent"
              >ім'я та прізвище;</span
              >
            </li>
            <li class="ql-align-justify ql-indent-1">
              <span style="background-color: transparent"
              >контактний телефон;</span
              >
            </li>
            <li class="ql-align-justify ql-indent-1">
              <span style="background-color: transparent"
              >електронна пошта.</span
              >
            </li>
          </ul>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">2.2 </span>
            <span style="background-color: transparent"
            >Також, ми отримуємо від вас цю інформацію по телефону.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">2.3 </span>
            <span style="background-color: transparent"
            >Інша інформація надається користувачем на його розсуд.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">2.4 </span>
            <span style="background-color: transparent">Веб-сайт </span
            ><a
            href="http://www.platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="color: rgb(0, 0, 255)"
          >www.platforma.life</a
          ><span style="background-color: transparent"
          >&nbsp; не збирає ip-адреси користувачів.</span
          >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">3.</strong>
            <strong style="background-color: transparent"
            >ЦІЛІ ЗБОРУ ТА ОБРОБКИ</strong
            >
          </p>
          <p class="ql-align-center">
            <strong style="background-color: transparent; color: rgb(0, 0, 0)"
            >ПЕРСОНАЛЬНИХ ДАНИХ КОРИСТУВАЧІВ</strong
            >
          </p>
          <p class="ql-align-center"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.1 </span>
            <span style="background-color: transparent"
            >Метою обробки персональних даних є забезпечення реалізації
              цивільно-правових відносин, надання/отримання та здійснення
              розрахунків за придбаними послугами.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.2 </span>
            <span style="background-color: transparent"
            >Веб-сайт збирає і зберігає тільки ті особисті дані, які необхідні
              для надання послуг.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.3 </span>
            <span style="background-color: transparent"
            >Веб-сайт збирає дані для зв'язку з користувачем, включаючи
              повідомлення про надання послуг, інформування користувача про
              надання послуг, а також для обробки запитів і заявок від
              користувачів.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">3.4 </span>
            <span style="background-color: transparent"
            >Обробка персональних даних користувача здійснюється без обмеження
              терміну, будь-яким засобом.</span
            >
          </p>
          <p><br /></p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">4.</strong>
            <strong style="background-color: transparent"
            >УМОВИ НАДАННЯ ДОСТУПУ ДО БАЗИ ДАНИХ</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.1 </span>
            <span style="background-color: transparent"
            >Ми не передаємо персональні дані користувачів третім особам крім
              випадків визначених законодавством України.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.2 </span>
            <span style="background-color: transparent"
            >Розкриття персональних даних користувача здійснюється при
              замовленні та отримання користувачем послуг через Веб-сайт.
              Зокрема користувач погоджується з тим, що Веб-сайт має право
              передавати особисті дані, виключно з метою виконання своїх
              зобов'язань перед користувачами.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.3 </span>
            <span style="background-color: transparent"
            >При втраті або розголошенні персональних даних Веб-сайт інформує
              негайно користувача.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">4.4 </span>
            <span style="background-color: transparent"
            >Веб-сайт вживає необхідних організаційних і технічних заходів для
              захисту персональної інформації від неправомірного або випадкового
              доступу, знищення, блокування, копіювання, поширення, а також від
              інших неправомірних дій третіх осіб.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">5.</strong>
            <strong style="background-color: transparent"
            >ЗМІНА ПЕРСОНАЛЬНОЇ ІНФОРМАЦІЇ КОРИСТУВАЧІВ</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">5.1 </span>
            <span style="background-color: transparent"
            >Користувач може в будь-який момент змінити (оновити, доповнити,
              видалити) надану їм персональну інформацію або її частину,
              надіславши електронного листа з відповідною вимогою на адресу </span
            ><a
            href="mailto:contact@platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="background-color: transparent; color: rgb(0, 0, 255)"
          >contact@platforma.life</a
          ><span style="background-color: transparent">.</span>
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">6.</strong>
            <strong style="background-color: transparent"
            >ЗМІНА ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.1 </span>
            <span style="background-color: transparent"
            >Ми маємо право змінити умови політики конфіденційності. В такому
              випадку, ми замінимо версію документа на сторінці "політика
              конфіденційності". Будь ласка, періодично переглядайте ці умови,
              щоб бути проінформованими про те, як Веб-сайт захищає особисті
              дані своїх користувачів.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">6.2 </span>
            <span style="background-color: transparent"
            >У разі незгоди з будь-якими змінами, внесеними до Правил,
              Користувач повинен припинити користуватися сайтом, а також
              вимагати, щоб Адміністрація сайту видалила його Персональні
              дані.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">7.</strong>
            <strong style="background-color: transparent"
            >ПОЛІТИКА ЩОДО ФАЙЛІВ COOKIE</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">7.1 </span>
            <span style="background-color: transparent"
            >Сайт збирає інформацію про відвідування Вами наших веб-сайтів з
              метою отримання статистики відвідувань і ефективності їх
              використання, формування персонального підходу і адаптації до
              інтересів кожного користувача. Ми робимо це за допомогою різних
              технологій, одна з яких - cookie-файли. Це дані, які веб-сайт може
              відправити вашому браузеру, і вони будуть збережені на вашому
              комп'ютері для подальшої ідентифікації його веб-сайтом.</span
            >
          </p>
          <p class="ql-indent-1"><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">7.2 </span>
            <span style="background-color: transparent"
            >Cookie-файли використовуються для оцінки ефективності веб-сайту
              (наприклад, для вимірювання кількості відвідувачів і тривалості їх
              перебування на сайті), виявлення найбільш цікавих і відвідуваних
              тем і сторінок, а також для забезпечення зручності навігації та
              користування сайтом, без збору будь-якої персональної інформації.
              Також cookie-файли можуть бути використані для формування
              персонального підходу на основі вже наявного досвіду взаємодії
              користувача з сайтом і його перевагами. З плином часу ця
              інформація дозволяє поліпшити досвід.</span
            >
          </p>
          <p><br /></p>
          <p class="ql-align-center">
            <strong style="background-color: transparent">8.</strong>
            <strong style="background-color: transparent"
            >ЗВОРОТНІЙ ЗВ'ЯЗОК.</strong
            >
          </p>
          <p><br /></p>
          <p class="ql-align-justify ql-indent-1">
            <span style="background-color: transparent">8.1 </span>
            <span style="background-color: transparent"
            >Всі пропозиції або питання з приводу цієї політики слід
              повідомляти Веб-сайту за адресою </span
            ><a
            href="mailto:contact@platforma.life"
            rel="noopener noreferrer"
            target="_blank"
            style="background-color: transparent; color: rgb(0, 0, 255)"
          >contact@platforma.life</a
          ><span style="background-color: transparent">.</span>
          </p>
          <p><br /></p>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">Приймаю</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'personalData',
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    getuserLocale () {
      return this.$store.getters['app/getSelectedLocale'] === 'uk'
    }
  }
}
</script>

<style scoped>
.blue-font {
  color: #00a4b7 !important;
}
</style>
